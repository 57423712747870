import React, { useState } from 'react';
import CalendarSaveV2 from 'components/CalendarSaveV2';
import CopyTextButton from '../CopyTextButton';
import './styles.css';

const EventList = ({ events, sortEventsByDate = true }) => {
  if (!events) return null;

  const [expandedDesc, setExpandedDesc] = useState(null); // ID of the expanded event description

  const isPastEvent = eventDate => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight
    return new Date(eventDate) < today;
  };

  const sortEventsByDateFunc = events => {
    return [...events].sort((a, b) => a.date.localeCompare(b.date));
  };

  // Conditionally sort events if sortEventsByDate is true
  const displayedEvents = sortEventsByDate ? sortEventsByDateFunc(events) : events;

  return (
    <div className="container mt-4 eventList">
      {displayedEvents && displayedEvents.length
        ? displayedEvents.map((event, index) => {
            let month;

            // Skip events without a date
            if (!event || !event.date) {
              return null;
            }

            const date = new Date(event.date);
            month = date.getMonth() + 1;

            return (
              <div
                key={event.id}
                className={`row mb-4 py-3 px-1 bottom-border ${isPastEvent(event.date) ? 'past-event' : ''}  `} // Apply past-event class if event is in the past
              >
                {/* Row for mobile, columns for larger screens */}
                <div className="col-6 col-md-3">
                  <div className="display-1 text-primary">{`${month}/${event.day}`}</div>
                  <div className="text-muted">{event.weekday}</div>
                  <div className="text-muted">{event.month}</div>
                  <div>
                    {event.time} {event.timezone}
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="display-4 text-dark title-mobile">{event.title}</div>
                  <div className="mb-3 text-muted">{event.subtitle}</div>
                  <CopyTextButton
                    text={event.location}
                    buttonLabel="Copy Zoom Link"
                    alertMessage={'Zoom Link Copied'}
                  />
                  <CalendarSaveV2 event={event} />
                </div>

                {/* New row for mobile, continuation for larger screens */}
                <div className={`col-6 col-md-3 mt-4 mt-md-0 ${index % 2 === 0 ? 'order-3' : 'order-4'}`}>
                  <div className="event-image-container">
                    <img
                      src={event.imageURL}
                      alt={event.title}
                      className="img-fluid event-image"
                      style={{ borderRadius: '10px', padding: '0px' }}
                    />
                  </div>
                </div>

                <div className={`col-6 col-md-3 mt-4 mt-md-0 ${index % 2 === 0 ? 'order-4' : 'order-3'}`}>
                  <div
                    className="event-description"
                    style={{
                      maxHeight: expandedDesc === event.id ? '100%' : '100px'
                    }}
                  >
                    {event.description}
                    {expandedDesc !== event.id ? (
                      <button className="btn btn-link expand-description-btn" onClick={() => setExpandedDesc(event.id)}>
                        Show more
                      </button>
                    ) : (
                      <button className="btn btn-link expand-description-btn" onClick={() => setExpandedDesc(null)}>
                        Show less
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default EventList;
