import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Col, Container, Card, CardImg, CardBody, CardTitle, CardText, Row, Button, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faSignalStream } from '@fortawesome/pro-solid-svg-icons/faSignalStream';

const CarouselV3 = ({
  cards,
  darkText = false,
  fullWidth = false,
  handleNav,
  description,
  noDescription = false,
  noMetadata = false,
  noSubtitle = false,
  linkTo,
  title,
  sortBy,
  sortOrder = 'desc',
  variant,
  onReplay = [],
  live = [],
  premieresText = 'Premieres'
}) => {
  const containerUlRef = useRef(null);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  const onCarouselScroll = useCallback(el => {
    const carousel = el?.currentTarget ?? containerUlRef.current;
    if (!carousel || !carousel.firstChild || !carousel.lastChild) return;
    const { left, right } = carousel.getBoundingClientRect();
    setPrevDisabled(left <= carousel.firstChild.getBoundingClientRect().left);
    setNextDisabled(right >= carousel.lastChild.getBoundingClientRect().right);
  }, []);

  const page = useCallback((dir = 'right') => {
    //get the bounds of the wrapper
    const el = containerUlRef.current;
    if (el === null || typeof el === 'undefined') return;
    const boundingBox = el.getBoundingClientRect();
    const bounds = dir === 'right' ? boundingBox.right : boundingBox.left;
    //find the first element that is partially or fully in the bounds
    const nextEl = Array.from(el.children).find(c =>
      dir === 'right' ? c.getBoundingClientRect().right > bounds : c.getBoundingClientRect().left < bounds
    );
    if (nextEl === null || typeof nextEl === 'undefined') return;
    nextEl.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
  }, containerUlRef);

  const prevPage = useCallback(() => page('left'));
  const nextPage = useCallback(() => page('right'));

  useEffect(() => {
    onCarouselScroll();
  });

  // A handy utility function to access nested properties safely
  // Works for both single-level (e.g., "title") and multi-level paths (e.g., "data.time")
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  // Let's sort the cards array if 'sortBy' exists, respecting the desired 'sortOrder' (asc/desc)
  const sortedCards = sortBy
    ? [...cards].sort((a, b) => {
        // We fetch the values from our objects based on the 'sortBy' path
        const aValue = getNestedValue(a, sortBy);
        const bValue = getNestedValue(b, sortBy);

        // Let's attempt to convert these values into proper Date objects, just in case they're date strings
        const aDate = aValue ? new Date(aValue) : null;
        const bDate = bValue ? new Date(bValue) : null;

        let comparison = 0; // This will help us track the result of our comparison

        // If both values are valid dates, let's compare them the right way
        if (aDate instanceof Date && !isNaN(aDate) && bDate instanceof Date && !isNaN(bDate)) {
          comparison = aDate - bDate; // Easy peasy, date comparison
        }
        // If they're strings, we'll use localeCompare to handle all the fun quirks of string comparison
        else if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue);
        }
        // If they're numbers, the comparison is super straightforward
        else if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        }

        // Now for the magic touch: if sortOrder is 'desc', we'll simply flip our comparison
        return sortOrder === 'desc' ? -comparison : comparison;
      })
    : cards; // If there's no 'sortBy', no worries – we'll just return the original cards array as-is

  const header = (
    <h5 className={classNames('text-white d-flex align-items-center mr-3', darkText ? `text-dark` : '')}>
      {title}{' '}
      {linkTo ? (
        <>
          &nbsp; <FontAwesomeIcon icon={faCaretRight} />
        </>
      ) : null}
    </h5>
  );

  return (
    <Container size="2xl" className={classNames('carousel', variant ? `carousel-${variant}` : '')}>
      <Row className={fullWidth ? '' : 'px-sm-5'}>
        <Col>
          {linkTo ? (
            <Link to={linkTo} className="text-small text-white">
              {header}
            </Link>
          ) : (
            header
          )}
          {description ? (
            <p className={classNames('text-white mb-3', darkText ? `text-dark` : '')}>{description}</p>
          ) : null}
        </Col>
      </Row>
      <Row className={classNames('carouselContainer', fullWidth ? '' : 'px-sm-5')}>
        <ul className="carouselNav d-none d-sm-block">
          <li>
            <Button onClick={prevPage} color="link" disabled={prevDisabled} className={`carouselNav-prev`}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </li>
          <li>
            <Button onClick={nextPage} color="link" disabled={nextDisabled} className={`carouselNav-next`}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </li>
        </ul>
        <Col xs="auto">
          <ul ref={containerUlRef} onScroll={onCarouselScroll}>
            {sortedCards && sortedCards.length
              ? sortedCards.map((card, i) => {
                  const {
                    date = null,
                    duration = null,
                    image = null,
                    subtitle = null,
                    title = null,
                    description = null
                  } = card.displayProps || {};
                  let durationObject, dateObject;
                  const { id, slug, type, data } = card;
                  if (duration) {
                    durationObject = moment.duration(duration);
                  }
                  if (date) {
                    dateObject = new Date(date);
                  }

                  return (
                    <Card
                      onClick={
                        slug
                          ? () =>
                              handleNav(
                                null,
                                type,
                                type === 'link'
                                  ? data?.url ?? slug
                                  : type === 'zoomMeeting'
                                  ? data?.recordingUrl ?? slug
                                  : slug,
                                null
                              )
                          : null
                      }
                      tag="li"
                      style={{ minWidth: 200 }}
                      key={id}
                    >
                      <CardImg
                        alt={image ? image.alt : 'No image alt available'}
                        src={image ? image.url : ''}
                        top
                        width="100%"
                      />
                      <CardBody>
                        <CardTitle tag="h6">{title}</CardTitle>
                        {!noSubtitle && subtitle ? <CardText className="subtitle">{subtitle}</CardText> : null}
                        {!noDescription && description ? (
                          <CardText className="description">{description}</CardText>
                        ) : null}
                      </CardBody>
                      {!noMetadata && (duration || date) ? (
                        <CardFooter>
                          {live.includes(i) ? (
                            <div style={{ color: 'var(--red)', fontWeight: 600 }}>
                              <FontAwesomeIcon icon={faSignalStream} />
                              &nbsp; Live Now!
                            </div>
                          ) : null}
                          {!onReplay.includes(i) && !live.includes(i) ? (
                            <>
                              {duration ? (
                                <small className="text-muted mr-2">{`${
                                  durationObject.hours() ? `${durationObject.hours()} hrs ` : ''
                                }${durationObject.minutes()} min${durationObject.minutes() > 1 ? 's' : ''}`}</small>
                              ) : null}
                              {!live.includes(id) && date ? (
                                <small className="text-muted mr-2">
                                  {dateObject > new Date() ? premieresText ? <>{premieresText}&nbsp;</> : '' : null}
                                  {dateObject.toLocaleDateString(undefined, { dateStyle: 'long' })}
                                </small>
                              ) : null}
                            </>
                          ) : null}
                        </CardFooter>
                      ) : null}
                    </Card>
                  );
                })
              : null}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default CarouselV3;
