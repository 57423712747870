import React, { Component } from 'react';
import { Container, Row, Button, Col } from 'reactstrap';
import ListPage from '../../../Products/products/PBCC/pages/ListPage';
import { ForumWrapper } from 'components/ForumWrapper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from 'modules/modal';
import { actions as userActions } from 'modules/user';
import { withPhaseProvider } from 'lib/scheduleContext';
import GeneralContentContainerApiComponents from 'containers/GeneralContentContainerApiComponents';
import { Switch, Route } from 'react-router-dom';
class CoachingProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'resources', // Tracks the active "page"
      height: 500 // Tracks the height for the iframe
    };

    this.messageHandler = this.messageHandler.bind(this);
    this.productSlug = location.pathname.split('/')[2];
  }

  componentDidMount() {
    // Add event listener when the component mounts
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler(ev) {
    if (ev.data.type === 'dimensions' && ev.origin === 'https://www.addevent.com') {
      const h = ev.data.h;
      this.setState({ height: h });
    }
  }

  render() {
    const { activePage, height } = this.state;
    const {
      Children,
      slug,
      noun = 'Content',
      nouns = 'Content',
      location,
      history,
      title,
      user,
      refreshToken,
      revokeDiscourseAPIKey
    } = this.props || {};

    const carousels = Children?.filter(c => c.type === 'list');
    const embedUrl = Children?.find(c => c.type === 'link' && c.title.includes('Calendar'))?.data?.url;
    const zoomUrl = Children?.find(c => c.type === 'link' && c.title.includes('Zoom'))?.data?.url;

    return (
      <Container style={{ minHeight: '100vh' }}>
        <Switch>
          {/* Route for the Coaching Dashboard and Forum */}
          <Route
            exact
            path={`/products/${this.productSlug}`}
            render={() => (
              <>
                {/* Navigation Buttons */}
                <Row className="mb-4 mt-5">
                  <Col xs="auto">
                    <Button
                      color={activePage === 'resources' ? 'primary' : 'secondary'}
                      onClick={() => this.setState({ activePage: 'resources' })}
                      className="mr-2"
                    >
                      Coaching Program Dashboard
                    </Button>
                    <Button
                      color={activePage === 'forum' ? 'primary' : 'secondary'}
                      onClick={() => this.setState({ activePage: 'forum' })}
                    >
                      Forum
                    </Button>
                  </Col>
                </Row>

                {/* Content */}
                {activePage === 'resources' ? (
                  <div>
                    <Row>
                      <Col>
                        <h4>Coaching Program Dashboard</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {embedUrl ? (
                          <iframe
                            className="mt-5"
                            src={embedUrl}
                            style={{ width: '100%', height: `${height}px`, border: 0 }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center">
                        {zoomUrl ? (
                          <Button color="primary" className="mt-5" onClick={() => window.open(zoomUrl, '_blank')}>
                            Private Coaching Room
                          </Button>
                        ) : null}
                      </Col>
                    </Row>

                    {carousels && carousels.length ? (
                      <ListPage
                        {...this.props}
                        location={location} // Pass location explicitly
                        history={history} // Pass history explicitly
                        title="Coaching Resources"
                        Children={carousels}
                      />
                    ) : (
                      <em>No Resources Available</em>
                    )}
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <h4>Forum</h4>
                      </Col>
                    </Row>
                    <ForumWrapper
                      {...this.props}
                      pageTitle={title}
                      product={this.productSlug}
                      refreshToken={refreshToken}
                      revokeDiscourseAPIKey={revokeDiscourseAPIKey}
                      forumType="Forum"
                      slug={slug}
                    />
                  </>
                )}
              </>
            )}
          />

          {/* Wildcard Route */}
          <Route
            key={'wildcard'}
            exact
            path={`/products/${this.productSlug}/:slug/`}
            component={props => {
              return (
                <GeneralContentContainerApiComponents
                  dataMassager={null} //used if data transformation is needed from the contentItem
                  Component={null}
                  componentProps={{ showComments: this.productSlug === 'pbcc' }}
                />
              );
            }}
          />
        </Switch>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data)),
  getUserProducts: callback => {
    dispatch(userActions.getUserProducts(callback));
  },
  refreshToken: userActions.refreshToken,
  revokeDiscourseAPIKey: userActions.revokeDiscourseAPIKey
});

const ConnectedListProduct = connect(mapStateToProps, mapDispatchToProps)(CoachingProduct);
const EnhancedListProduct = withPhaseProvider(withRouter(ConnectedListProduct));
export default withRouter(EnhancedListProduct);
