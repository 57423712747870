import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import CarouselV3 from 'routes/Products/components/CarouselV3';
class ListPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleNav = (name, typeOfComponent, slug) => {
    if (slug.startsWith('http://') || slug.startsWith('https://') || slug.startsWith('cdn://')) {
      window.open(slug, '_blank');
    } else {
      const thisPath = this.props.location.pathname.split('/');
      const thisProductInPath = thisPath[2];
      this.props.history.push(`/products/${thisProductInPath}/${slug}`);
      window.scrollTo(0, 0);
    }
  };

  render() {
    const { id, title, Children, loading, noun = 'Content', nouns = 'Content', sortBy } = this.props || {};
    const carousels = Children?.filter(c => c.type === 'list').map(({ title, Children }) => ({ title, Children }));

    const nonListChildren = Children?.filter(c => c.type !== 'list');
    if (nonListChildren?.length) {
      carousels.unshift({ title, Children: nonListChildren });
    }
    return (
      <Container key={id} style={{ minHeight: '100vh' }}>
        {title ? (
          <Row className="align-items-end">
            <Col xs="auto">
              <h4>{title}</h4>
            </Col>
          </Row>
        ) : null}
        {loading ? (
          <em>Loading...</em>
        ) : carousels?.length ? (
          carousels.map(({ title, Children }) => (
            <Row>
              <Col>
                <CarouselV3
                  title={title ?? ''}
                  cards={Children}
                  handleNav={this.handleNav}
                  darkText
                  variant="light"
                  noDescription
                  premieresText=""
                  sortBy={sortBy}
                />
              </Col>
            </Row>
          ))
        ) : (
          <em>No {nouns} Available</em>
        )}
      </Container>
    );
  }
}

ListPage.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default ListPage;
